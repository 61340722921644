<template>
  <v-container>
    <v-row>

      <v-col cols="12" class="text-center">
        <v-img src="../assets/ulink.png" contain max-height="60" class="mt-3 mb-5"></v-img>
        <h3>ELIMINAR DATOS</h3><br>
        Por favor introduce tu correo y click en el botón eliminar para no pertenecer más a la comunidad U-link:
      </v-col>

      <v-col cols="12">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-text-field 
            v-model="email"
            label="Email" 
            filled
            outlined 
            dense
            :rules="emailRules"
          ></v-text-field>
          <v-btn @click="validate" block color="primary" elevation="8" rounded :disabled="!valid">Eliminar Datos</v-btn>
          <!--<v-btn @click="delLocalStorage" block color="primary" elevation="8" rounded class="mt-3">LocalStorage</v-btn>-->
        </v-form>
      </v-col>

      <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      >
        Datos Eliminados
        <template v-slot:action="{ attrs }">
          <v-btn color="white" text v-bind="attrs" @click="snackbar = false">Close</v-btn>
        </template>
      </v-snackbar>
      
      <v-col cols="12" class="mt-6">
        <Powered />
      </v-col>

    </v-row>
  </v-container>
</template>

<script>
import { db } from "../firebase";
import { collection, query, getDocs, doc, deleteDoc } from "firebase/firestore";
//import { send } from "../smtp.js";


import Powered from '../components/Powered.vue'

export default {
  components: {
    Powered,
  },

  data() {
    return {
      valid: true,
      email: '',
      emailRules: [
        value => !!value || 'Requerido.',
        value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Formato de email inválido'
        },
      ],
      snackbar: false,
      timeout: 2000,
    }
  },

  methods: {
    delLocalStorage () {
      localStorage.clear()
    },

    validate () {
      if(this.$refs.form.validate()){
        this.unregister(this.email)
      }
    },

    async unregister(email){
      try {
        //DELETE CUSTOMS
        const customsArray = []
        const customs = query(collection(db, "custom"))
        const customsSnapshot = await getDocs(customs)
        customsSnapshot.forEach(item => {
          customsArray.push(item.id)
        })
        customsArray.forEach(async item => {
          await deleteDoc(doc(db, `custom/${item}/userskin/${email}`))
        })

        //DELETE GENERAL
        await deleteDoc(doc(db, `userskin/${email}`))

        //DELETE LOCALSTORARE
        localStorage.clear()

        //EXTRAS
        this.email=''
        this.snackbar = true


        //window.open("https://google.com", "_self");
      } catch (error) {
        console.log(error)
      }
    },
  },


}
</script>